import axios from 'axios';
import Cookies from "universal-cookie";
import { base_url } from '../../../config/globals';
const cookie = new Cookies();
const token = cookie.get("access_token");
export function getUsersData() {
    return new Promise((resolve, reject) => {
        axios.get(base_url + '/users', {
            headers: {
                "x-access-token": token
            }
        }).then((response) => {
            let data = response.data

            resolve(data)
        }).catch((err) => {
            err = JSON.parse(JSON.stringify(err))
            if (err.status === 401 && err.message === "Request failed with status code 401") {
                alert('you have no access to this page')
            }
            window.location.href = "/";

            reject(err)
        });
    })
}
export function getUsersBillsTransactionData() {
    return new Promise((resolve, reject) => {
        axios.get(base_url + '/transactions/getBillsTransactions', {
            headers: {
                "x-access-token": token
            }
        }).then((response) => {
            let data = response.data
            resolve(data)
        }).catch((err) => {
            err = JSON.parse(JSON.stringify(err))
            if (err.status === 401 && err.message === "Request failed with status code 401") {
                alert('you have no access to this page')
            }
            window.location.href = "/";
            reject(err)
        });
    })
}
export function updateAccountStatus(objectData) {
    return new Promise((resolve, reject) => {
        axios.post(base_url + "/users/updateAccountStatus", objectData, {
            headers: {
                "x-access-token": token
            }
        }).then((response) => {
            let responseData = JSON.stringify(response.data);
            if (responseData > 0) {
                resolve("Account Updated")
            }
            else {
                reject("An error occured while updating account " + responseData)
            }
        }).catch((err) => {
            err = JSON.parse(JSON.stringify(err))
            if (err.status === 401 && err.message === "Request failed with status code 401") {
                alert('you have no access to this page')
            }
            window.location.href = "/";
            console.log(err)
        })
    });
}
export function updateAccessAccountStatus(objectData) {
    return new Promise((resolve, reject) => {
        axios.post(base_url + "/users/updateAccessAccountStatus", objectData, {
            headers: {
                "x-access-token": token
            }
        }).then((response) => {

            let responseData = JSON.stringify(response.data);
            if (responseData > 0) {
                resolve("Access Point Updated")
            }
            else {
                reject("An error occured while updating accesspoint " + responseData)
            }

        }).catch((err) => {
            err = JSON.parse(JSON.stringify(err))
            if (err.status === 401 && err.message === "Request failed with status code 401") {
                alert('you have no access to this page')
            }
            window.location.href = "/";
            console.log(err)
        })
    });
}
