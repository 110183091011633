import axios from "axios";
import { base_url } from "../../../config/globals";
import Cookies from "universal-cookie";
const cookie = new Cookies();
const token = cookie.get('access_token');
export function getAirtimeChartData() {
    return new Promise((resolve, reject) => {
        axios.get(base_url + "/dashboard/airtimechart", {
            headers: {
                "x-access-token": token
            }
        }).then((result) => {
            resolve(result.data)

        }).catch(err => {
            reject(err)
        })
    })
}
export function getDataChartData() {
    return new Promise((resolve, reject) => {
        axios.get(base_url + "/dashboard/datachart", {
            headers: {
                "x-access-token": token
            }
        }).then((result) => {
            resolve(result.data)

        }).catch(err => {
            reject(err)
        })
    })
}
export function getElectricityChartData() {
    return new Promise((resolve, reject) => {
        axios.get(base_url + "/dashboard/getElectricityChart", {
            headers: {
                "x-access-token": token
            }
        }).then((result) => {
            resolve(result.data)
        }).catch(err => {
            reject(err)
        })
    })
}
export function getCableChartData() {
    return new Promise((resolve, reject) => {
        axios.get(base_url + "/dashboard/getCableChart", {
            headers: {
                "x-access-token": token
            }
        }).then((result) => {
            resolve(result.data)
        }).catch(err => {
            reject(err)
        })
    })
}
export function getFundingTransactionChartData() {
    return new Promise((resolve, reject) => {
        axios.get(base_url + "/dashboard/getFundingBarChart", {
            headers: {
                "x-access-token": token
            }
        }).then((result) => {
            resolve(result.data)
        }).catch(err => {
            reject(err)
        })
    })
}
export function getTransactionChartData() {
    return new Promise((resolve, reject) => {
        axios.get(base_url + "/dashboard/getMonthlyTransactionChart", {
            headers: {
                "x-access-token": token
            }
        }).then((result) => {
            resolve(result.data)
        }).catch(err => {
            reject(err)
        })
    })
}