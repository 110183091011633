import React from "react";
import { useState, useEffect, useRef } from "react";
import Pageheader from "../Pageheader";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { useLocation } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import capitalize from "../Helpers/capitalize";
import checkRole from "../../config/userroles";
import {
  updateAccountStatus,
  updateAccessAccountStatus,
  getUsersData,
} from "./userfunctions/apiCalls";
import getSeverity, {
  getNin,
  getAccess,
  accountStatus,
  accountAccessPointStatus,
} from "./userfunctions/userhelper";
import capitalizeFirst from "../Helpers/capitalizeFirst";
function Users() {
  const toast = useRef(null);
  const cols = [
    { field: "firstname", header: "First Name" },
    { field: "surname", header: "SurName" },
    { field: "phone", header: "Phone" },
    { field: "email", header: "Email" },
  ];
  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  const [submitted, setSubmitted] = useState(false);
  // const [submitted, setSubmitted] = useState(false);
  const [customers, setCustomers] = useState([]);
  let [customer, setCustomer] = useState({});
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    surname: { value: null, matchMode: FilterMatchMode.CONTAINS },
    firstname: { value: null, matchMode: FilterMatchMode.CONTAINS },
    email: { value: null, matchMode: FilterMatchMode.CONTAINS },
    phone: { value: null, matchMode: FilterMatchMode.EQUALS },
    account_status: { value: null, matchMode: FilterMatchMode.EQUALS },
    access_point: { value: null, matchMode: FilterMatchMode.EQUALS },
    nin_status: { value: null, matchMode: FilterMatchMode.EQUALS },
    phone_verified_at: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [loadingTable, setLoadingTable] = useState(true);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [statuses] = useState(["Active", "Suspended"]);
  const [accessstatuses] = useState(["True", "False"]);
  const [ninstatuses] = useState(["True", "False"]);
  const [customerAccessDialog, setCustomerAccessDialog] = useState(false);
  const [customerDialog, setCustomerDialog] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedAccessStatus, setSelectedAccessStatus] = useState(null);

  useEffect(() => {
    getUsersData()
      .then((res) => {
        setCustomers(res);
        setLoadingTable(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const capitalizedData = customers.map((item) => ({
    ...item,
    firstname: capitalize(item.firstname),
    surname: capitalize(item.surname),
    account_status:
      item.account_status === "1"
        ? "Active"
        : item.account_status === "0"
        ? "Suspended"
        : item.account_status === "Active"
        ? "Active"
        : item.account_status === "Suspended"
        ? "Suspended"
        : "",
    access_point: capitalizeFirst(item.access_point),
    nin_status:
      item.nin_status != null ? capitalizeFirst(item?.nin_status) : "False",
    phone_verified_at: item.phone_verified_at === null ? false : true,
  }));
  const hideDialog = () => {
    setSubmitted(false);
    setCustomerDialog(false);
    setCustomerAccessDialog(false);
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, customers);
        doc.save("customers.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(customers);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "users");
    });
  };
  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const renderHeader = () => {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>

          <div className="flex">
            <Button
              type="button"
              icon="pi pi-file-excel"
              severity="success"
              rounded
              onClick={exportExcel}
              data-pr-tooltip="XLS"
            />
            <Button
              type="button"
              icon="pi pi-file-pdf"
              severity="warning"
              rounded
              onClick={exportPdf}
              data-pr-tooltip="PDF"
            />
          </div>
        </div>
      </>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.account_status}
        severity={getSeverity(rowData.account_status)}
      />
    );
  };

  const accessBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.access_point}
        severity={getAccess(rowData.access_point)}
      />
    );
  };

  const ninBodyTemplate = (rowData) => {
    return (
      <Tag value={rowData.access_point} severity={getNin(rowData.nin_status)} />
    );
  };

  const statusItemTemplate = (option) => {
    return <Tag value={option} severity={getSeverity(option)} />;
  };

  const accessItemTemplate = (option) => {
    return <Tag value={option} severity={getAccess(option)} />;
  };
  const ninItemTemplate = (option) => {
    return <Tag value={option} severity={getNin(option)} />;
  };

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={statusItemTemplate}
        placeholder="Select Status"
        className="p-column-filter"
        showClear
        style={{ minWidth: "4rem", maxWidth: "12rem" }}
      />
    );
  };
  const accessRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={accessstatuses}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={accessItemTemplate}
        placeholder="Select Status"
        className="p-column-filter"
        showClear
        style={{ minWidth: "4rem", maxWidth: "11rem" }}
      />
    );
  };

  const ninRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={ninstatuses}
        onChange={(e) => [
          options.filterApplyCallback(e.value),
          console.log(e.value),
        ]}
        itemTemplate={ninItemTemplate}
        placeholder="Select Status"
        className="p-column-filter"
        showClear
        style={{ minWidth: "4rem", maxWidth: "11rem" }}
      />
    );
  };
  const verifiedBodyTemplate = (rowData) => {
    return (
      <i
        style={{ color: rowData.phone_verified_at ? "green" : "red" }}
        className={classNames("pi", {
          "text-green-500 pi-check-circle": rowData.phone_verified_at,
          "text-red-500 pi-times-circle": !rowData.phone_verified_at,
        })}
      ></i>
    );
  };

  const verifiedFilterTemplate = (options) => {
    return (
      <div className="flex align-items-center gap-2">
        {/* <label htmlFor="verified-filter" className="font-bold">
                    Toggle 
                </label> */}
        <Dropdown
          value={options.value}
          options={ninstatuses}
          onChange={(e) => [
            options.filterApplyCallback(e.value),
            console.log(e.value),
          ]}
          itemTemplate={ninItemTemplate}
          placeholder="Select Value"
          className="p-column-filter"
          showClear
          style={{ minWidth: "4rem", maxWidth: "12rem" }}
        />

        {/* <TriStateCheckbox inputId="verified-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} /> */}
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          outlined
          className="mr-2"
          onClick={() => editCustomers(rowData)}
        />
      </React.Fragment>
    );
  };
  const accessActionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          severity="warning"
          outlined
          className="mr-2"
          onClick={() => editAccessPointCustomers(rowData)}
        />
      </React.Fragment>
    );
  };
  const editCustomers = (customer) => {
    //console.log(customer)
    setCustomer({ ...customer });
    setCustomerDialog(true);
  };
  const editAccessPointCustomers = (customer) => {
    setCustomer({ ...customer });
    setCustomerAccessDialog(true);
  };
  const findIndexById = (id) => {
    let index = -1;

    for (let i = 0; i < customers.length; i++) {
      if (customers[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  };
  const customerDialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        label="Save"
        icon="pi pi-check"
        onClick={() => [
          setLoadingTable(true),
          updateAccountStatus({
            userID: customer.id,
            accountStatus: selectedStatus === "Activate Account" ? 1 : 0,
          })
            .then((res) => {
              if (res === "Account Updated") {
                setLoadingTable(false);
                setCustomerDialog(false);
                toast.current.show({
                  severity: "success",
                  summary: "Successful",
                  detail: res,
                });
                let _customers = [...customers];
                let _customer = { ...customer };

                if (_customer.id) {
                  const index = findIndexById(customer.id);
                  _customer = {
                    ..._customer,
                    account_status:
                      selectedStatus === "Activate Account" ? "1" : "0",
                  };
                  _customers[index] = _customer;
                }
                setCustomers(_customers);
                setCustomer(_customer);
              }
            })
            .catch((error) => {
              setLoadingTable(false);
              alert("error" + error);
            }),
        ]}
      />
    </React.Fragment>
  );

  const customerAccessDialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        label="Save"
        icon="pi pi-check"
        onClick={() => [
          setLoadingTable(true),
          updateAccessAccountStatus({
            userID: customer.id,
            accessStatus: selectedAccessStatus.toLowerCase(),
          })
            .then((res) => {
              if (res === "Access Point Updated") {
                setLoadingTable(false);
                toast.current.show({
                  severity: "success",
                  summary: "Successful",
                  detail: res,
                });
                let _customers = [...customers];
                let _customer = { ...customer };

                if (_customer.id) {
                  const index = findIndexById(customer.id);
                  _customer = {
                    ..._customer,
                    access_point: selectedAccessStatus,
                  };
                  _customers[index] = _customer;
                }
                setCustomers(_customers);
                setCustomer(_customer);
              }
            })
            .catch((error) => {
              setLoadingTable(false);
              alert("error" + error);
            }),
        ]}
      />
    </React.Fragment>
  );
  const onInputChange = (e, name) => {
    // const val = (e.target && e.target.value) || '';
    // let _product = { ...product };
    // _product[`${name}`] = val;
    // setProduct(_product);
  };
  const location = useLocation();
  // Access the current URL path
  const currentPath = window.location.pathname;
  // Split the path by '/' to get individual segments
  const pathSegments = currentPath.split("/");
  const header = renderHeader();
  return (
    <div className="pcoded-content">
      <Toast ref={toast} />
      {/* Page-header start */}
      <Pageheader title={pathSegments[2]} subtitle={pathSegments[2]} />
      {/* Page-header end */}
      <div className="pcoded-inner-content">
        {/* Main-body start */}
        <div className="main-body">
          <div className="page-wrapper">
            {/* Page-body start */}
            <div className="page-body">
              <div className="card">
                <DataTable
                  value={capitalizedData}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  paginator
                  rows={10}
                  dataKey="id"
                  filters={filters}
                  filterDisplay="row"
                  loading={loadingTable}
                  globalFilterFields={[
                    "firstname",
                    "surname",
                    "phone",
                    "email",
                  ]}
                  header={header}
                  emptyMessage="No customers found."
                >
                  <Column
                    field="firstname"
                    header="First Name"
                    filterPlaceholder="Search Firstname"
                    frozen={true}
                    className="font-bold"
                    style={{ maxWidth: "12rem", minWidth: "12rem" }}
                  />
                  <Column
                    header="Surname"
                    field="surname"
                    style={{ maxWidth: "12rem", minWidth: "12rem" }}
                    filterPlaceholder="Search Surname"
                  />
                  <Column
                    field="phone"
                    header="Phone Number"
                    style={{ maxWidth: "12rem", minWidth: "12rem" }}
                    filterPlaceholder="Search Phone"
                  />
                  <Column
                    field="account_status"
                    header="Account Status"
                    showFilterMenu={false}
                    filterMenuStyle={{ width: "14rem" }}
                    style={{ minWidth: "12rem" }}
                    body={statusBodyTemplate}
                    filter
                    filterElement={statusRowFilterTemplate}
                  />
                  <Column
                    field="access_point"
                    header="Access_Point"
                    showFilterMenu={false}
                    filterMenuStyle={{ width: "14rem" }}
                    style={{ minWidth: "12rem" }}
                    body={accessBodyTemplate}
                    filter
                    filterElement={accessRowFilterTemplate}
                  />
                  <Column
                    field="nin_status"
                    header="NIN Status"
                    showFilterMenu={false}
                    filterMenuStyle={{ width: "14rem" }}
                    style={{ minWidth: "12rem" }}
                    body={ninBodyTemplate}
                    filter
                    filterElement={ninRowFilterTemplate}
                  />
                  <Column
                    field="phone_verified_at"
                    header="Verified"
                    dataType="boolean"
                    showFilterMenu={false}
                    style={{ minWidth: "4rem" }}
                    filterPlaceholder=""
                    body={verifiedBodyTemplate}
                    filter
                    filterElement={verifiedFilterTemplate}
                  />
                  <Column
                    field="email"
                    header="Email"
                    style={{ minWidth: "12rem" }}
                    filter
                    filterPlaceholder="Search Email"
                  />
                  <Column
                    header="Set Account"
                    body={actionBodyTemplate}
                    exportable={false}
                    style={{ minWidth: "12rem" }}
                  ></Column>
                  <Column
                    header="Set Access Point"
                    body={accessActionBodyTemplate}
                    exportable={false}
                    style={{ minWidth: "12rem" }}
                  ></Column>
                </DataTable>
              </div>

              <Dialog
                visible={customerDialog}
                style={{ width: "32rem" }}
                breakpoints={{ "960px": "75vw", "641px": "90vw" }}
                header="Account Update"
                modal
                className="p-fluid"
                footer={customerDialogFooter}
                onHide={hideDialog}
              >
                <div className="field">
                  <label
                    htmlFor="name"
                    style={{ fontWeight: "bold" }}
                    className="font-bold"
                  >
                    Update Account For{" "}
                    {customer.firstname + " " + customer.surname}
                  </label>
                  <div className="card flex justify-content-center">
                    <Dropdown
                      value={selectedStatus}
                      onChange={(e) => setSelectedStatus(e.value)}
                      options={accountStatus}
                      optionLabel="value"
                      placeholder="Select Status"
                      className="w-full md:w-14rem"
                    />
                  </div>
                </div>
              </Dialog>

              <Dialog
                visible={customerAccessDialog}
                style={{ width: "32rem" }}
                breakpoints={{ "960px": "75vw", "641px": "90vw" }}
                header="Account Update"
                modal
                className="p-fluid"
                footer={customerAccessDialogFooter}
                onHide={hideDialog}
              >
                <div className="field">
                  <label
                    htmlFor="name"
                    style={{ fontWeight: "bold" }}
                    className="font-bold"
                  >
                    Update access point status for{" "}
                    {customer.firstname + " " + customer.surname}
                  </label>
                  <div className="card flex justify-content-center">
                    <Dropdown
                      value={selectedAccessStatus}
                      onChange={(e) => setSelectedAccessStatus(e.value)}
                      options={accountAccessPointStatus}
                      optionLabel="value"
                      placeholder="Select Status"
                      className="w-full md:w-14rem"
                    />
                  </div>
                </div>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Users;
