import axios from "axios";
import { base_url } from "../../../config/globals";
import Cookies from "universal-cookie";
const cookie = new Cookies();
const token = cookie.get('access_token');
export function getAdmins() {
    return new Promise((resolve, reject) => {
        axios.get(base_url + '/admin/getAdmins', {
            headers: {
                "x-access-token": token
            }
        }).then(response => {
            let data = response.data
            resolve(data)
        }).catch(err => {
            reject(err)
        })
    })
}
export function getAdminRoles() {
    return new Promise((resolve, reject) => {
        axios.get(base_url + '/admin/getAdminRoles', {
            headers: {
                "x-access-token": token
            }
        }).then(response => {
            let data = response.data
            resolve(data)
        }).catch(err => {
            err = JSON.parse(JSON.stringify(err))
            if (err.status === 401 && err.message === "Request failed with status code 401") {
                alert('you have no access to this page')
            }
            window.location.href = "/";
            reject(err)
        })
    })
}
export function updateAccountStatus(objectData) {
    return new Promise((resolve, reject) => {
        axios.post(base_url + "/admin/updateAccountStatus", objectData, {
            headers: {
                "x-access-token": token
            }
        }).then((response) => {
            let responseData = JSON.stringify(response.data);
            if (responseData > 0) {
                resolve("Account Updated")
            }
            else {
                reject("An error occured while updating account " + responseData)
            }
        }).catch((err) => {
            err = JSON.parse(JSON.stringify(err))
            if (err.status === 401 && err.message === "Request failed with status code 401") {
                alert('you have no access to this page')
            }
            window.location.href = "/";
            console.log(err)
        })
    });

}

export function registerAccount(objectData) {
    return new Promise((resolve, reject) => {
        axios.post(base_url + "/admin/createAccount", objectData, {
            headers: {
                "x-access-token": token
            }
        }).then((response) => {
            let responseData = JSON.stringify(response.data)
            if (responseData > 0) {
                resolve('Account created successfully')
            }
            else {
                reject('Account not created successfully' + JSON.stringify(response))
            }

        }).catch((err) => {
            err = JSON.parse(JSON.stringify(err))
            if (err.status === 401 && err.message === "Request failed with status code 401") {
                alert('you have no access to this page')
            }
            window.location.href = "/";
            console.log(err)
        })
    })
}