import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Pageheader from "./Pageheader";
import Transactioncharts from "./charts/Transactioncharts";
import Airtimecharts from "./charts/Airtimecharts";
import Datacharts from "./charts/Datacharts";
import Cablecharts from "./charts/Cablecharts";
import Userscount from "./blocks/Userscount";
import Sidebar from "./Sidebar";
import Activeuserscount from "./blocks/Activeuserscount";
import Accesspointscount from "./blocks/Accesspointscount";
import Walletscount from "./blocks/Walletscount";
import Transactionscount from "./blocks/Transactionscount";
import Pendingtransactionscount from "./blocks/Pendingtransactions";
import Fundingsum from "./blocks/Fundingsum";
import Transactionsum from "./blocks/Transactionsum";
import checkRole from "../config/userroles";
function Home() {
  useEffect(() => {
    const token = checkRole("access_token");
    if (token === "" || token === undefined) {
      window.location.href = "/login";
    }
  });
  const location = useLocation();
  // Access the current URL path
  const currentPath = window.location.pathname;
  // Split the path by '/' to get individual segments
  const pathSegments = currentPath.split("/");
  return (
    <div className="pcoded-content">
      {/* Page-header start */}
      <Pageheader title={pathSegments[1]} />
      {/* Page-header end */}
      <div className="pcoded-inner-content">
        {/* Main-body start */}
        <div className="main-body">
          <div className="page-wrapper">
            {/* Page-body start */}
            <div className="page-body">
              <div className="row">
                {/* Material statustic card start */}
                <div className="col-xl-4 col-md-12">
                  <div className="card mat-stat-card">
                    <div className="card-block">
                      <div className="row align-items-center b-b-default">
                        <Userscount />
                        <Activeuserscount />
                      </div>
                      <div className="row align-items-center">
                        <Transactionscount />
                        <div className="col-sm-6 p-b-20 p-t-20">
                          <Pendingtransactionscount />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-12">
                  <div className="card mat-stat-card">
                    <div className="card-block">
                      <div className="row align-items-center b-b-default">
                        <Accesspointscount />
                        <Walletscount />
                      </div>
                      <div className="row align-items-center">
                        <div className="col-sm-6 p-b-20 p-t-20 b-r-default">
                          <div className="row align-items-center text-center">
                            <div className="col-4 p-r-0">
                              <i className="fas fa-signal text-c-red f-24" />
                            </div>
                            <div className="col-8 p-l-0">
                              <h5>350</h5>
                              <p className="text-muted m-b-0">Afil</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 p-b-20 p-t-20">
                          <div className="row align-items-center text-center">
                            <div className="col-4 p-r-0">
                              <i className="fas fa-wifi text-c-blue f-24" />
                            </div>
                            <div className="col-8 p-l-0">
                              <h5>100%</h5>
                              <p className="text-muted m-b-0">Connections</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-12">
                  <Fundingsum />
                  <Transactionsum />
                </div>
                {/* Material statustic card end */}
                {/* order-visitor start */}
                {/* order-visitor end */}
                {/*  sale analytics start */}
                <div className="col-xl-6 col-md-12">
                  <Transactioncharts />
                </div>
                <div className="col-xl-6 col-md-12">
                  <div className="row">
                    {/* sale card start */}
                    <Airtimecharts vendors="AIRTIME" />
                    <Datacharts vendors="DATA" />

                    <Cablecharts title="CABLE" />

                    <div className="col-md-6">
                      <div className="card text-center order-visitor-card">
                        <div className="card-block">
                          <h6 className="m-b-0">Unique Visitors</h6>
                          <h4 className="m-t-15 m-b-15">
                            <i className="fa fa-arrow-down m-r-15 text-c-red" />
                            652
                          </h4>
                          <p className="m-b-0">36% From Last 6 Months</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card text-center order-visitor-card">
                        <div className="card-block">
                          <h6 className="m-b-0">Monthly Affiliate Earnings</h6>
                          <h4 className="m-t-15 m-b-15">
                            <i className="fa fa-arrow-up m-r-15 text-c-green" />
                            5963
                          </h4>
                          <p className="m-b-0">36% From Last 6 Months</p>
                        </div>
                      </div>
                    </div>
                    {/* sale card end */}
                  </div>
                </div>
                {/*  sale analytics end */}
                {/* Project statustic start */}
                <div className="col-xl-12">
                  <div className="card proj-progress-card">
                    <div className="card-block">
                      <div className="row">
                        <div className="col-xl-3 col-md-6">
                          <h6>Published Project</h6>
                          <h5 className="m-b-30 f-w-700">
                            532
                            <span className="text-c-green m-l-10">+1.69%</span>
                          </h5>
                          <div className="progress">
                            <div
                              className="progress-bar bg-c-red"
                              style={{ width: "25%" }}
                            />
                          </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                          <h6>Completed Task</h6>
                          <h5 className="m-b-30 f-w-700">
                            4,569
                            <span className="text-c-red m-l-10">-0.5%</span>
                          </h5>
                          <div className="progress">
                            <div
                              className="progress-bar bg-c-blue"
                              style={{ width: "65%" }}
                            />
                          </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                          <h6>Successfull Task</h6>
                          <h5 className="m-b-30 f-w-700">
                            89%
                            <span className="text-c-green m-l-10">+0.99%</span>
                          </h5>
                          <div className="progress">
                            <div
                              className="progress-bar bg-c-green"
                              style={{ width: "85%" }}
                            />
                          </div>
                        </div>
                        <div className="col-xl-3 col-md-6">
                          <h6>Ongoing Project</h6>
                          <h5 className="m-b-30 f-w-700">
                            365
                            <span className="text-c-green m-l-10">+0.35%</span>
                          </h5>
                          <div className="progress">
                            <div
                              className="progress-bar bg-c-yellow"
                              style={{ width: "45%" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Project statustic end */}
              </div>
            </div>
            {/* Page-body end */}
          </div>
          <div id="styleSelector"> </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
