import axios from "axios";
import { base_url } from "../../../config/globals";
import Cookies from 'universal-cookie';
const cookie = new Cookies();
const token = cookie.get('access_token');
export function getAccessPointCount() {
    return new Promise((resolve, reject) => {
        const options = {
            url: base_url + "/dashboard/accesspointscount",
            method: "GET",
            headers: {
                "Accept": "application/json",
                "x-access-token": token
            },
        };
        axios(options)
            .then((response) => {
                let count = JSON.parse(JSON.stringify(response.data));
                if (count.total > 1) {
                    resolve(count.total)
                } else {
                    reject("No records found")
                }
            })
            .catch((err) => {
                err = JSON.parse(JSON.stringify(err))
                if (err.status === 401 && err.message === "Request failed with status code 401") {
                    alert('you have no access to this page')
                }
                window.location.href = "/";
                reject(JSON.stringify(err));
            });
    })

}

export function getActiveUsersCount() {
    return new Promise((resolve, reject) => {
        const options = {
            url: base_url + "/dashboard/activeuserscount",
            method: "GET",
            headers: {
                "Accept": "application/json",
                "x-access-token": token
            },
        };
        axios(options)
            .then((response) => {
                let count = JSON.parse(JSON.stringify(response.data));
                resolve(count.total)
                //setActiveUsers(count.total);
            }).catch((error) => {
                reject(JSON.stringify(error));
            });
    })
}
export function getFundingSum() {
    return new Promise((resolve, reject) => {
        const options = {
            url: base_url + "/dashboard/fundingsum",
            method: "GET",
            headers: {
                "Accept": "application/json",
                "x-access-token": token
            },
        };
        axios(options)
            .then((response) => {
                let count = JSON.parse(JSON.stringify(response.data));
                resolve(count.total)
            })
            .catch((error) => {
                reject(JSON.stringify(error));
            });
    })
}
export function getPendingTransactionCount() {
    return new Promise((resolve, reject) => {
        const options = {
            url: base_url + "/dashboard/pendingtransactionscount",
            method: "GET",
            headers: {
                "Accept": "application/json",
                "x-access-token": token
            },
        };
        axios(options)
            .then((response) => {
                let count = JSON.parse(JSON.stringify(response.data));
                resolve(count.total)
            })
            .catch((error) => {
                reject(JSON.stringify(error));
            });
    })
}
export function getTransactionCount() {
    return new Promise((resolve, reject) => {
        const options = {
            url: base_url + "/dashboard/transactionscount",
            method: "GET",
            headers: {
                "Accept": "application/json",
                "x-access-token": token
            },
        };
        axios(options)
            .then((response) => {
                let count = JSON.parse(JSON.stringify(response.data));
                resolve(count.total);
            })
            .catch((error) => {
                reject(JSON.stringify(error));
            });
    })
}

export function getTransactionSum() {
    return new Promise((resolve, reject) => {
        const options = {
            url: base_url + '/dashboard/transactionsum',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                "x-access-token": token

            }
        }
        axios(options).then((response) => {
            let count = JSON.parse(JSON.stringify(response.data))
            let formt = count.total.toString().replace('-', '')
            resolve(formt)
        }).catch((error) => {
            reject(JSON.stringify(error))
        })
    })
}

export function getUsersCount() {
    return new Promise((resolve, reject) => {
        const options = {
            url: base_url + "/dashboard/userscount",
            method: "GET",
            headers: {
                "Accept": "application/json",
                "x-access-token": token
            },
        };
        axios(options)
            .then((response) => {
                let count = JSON.parse(JSON.stringify(response.data));
                resolve(count.total);
            })
            .catch((error) => {
                reject(JSON.stringify(error));
            });
    })
}

export function getWalletCount() {
    return new Promise((resolve, reject) => {
        const options = {
            url: base_url + "/dashboard/walletscount",
            method: "GET",
            headers: {
                "Accept": "application/json",
                "x-access-token": token
            },
        };
        axios(options)
            .then((response) => {
                let count = JSON.parse(JSON.stringify(response.data));
                resolve(count.total);
            })
            .catch((error) => {
                reject(JSON.stringify(error));
            });
    })
}
