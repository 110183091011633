import React from "react";
import { useEffect, useState } from "react";
import { getPendingTransactionCount } from "./blocksapicalls/apicalls";
function Pendingtransactionscount() {
  const [pendingTransactions, setPendingTransactions] = useState(0);
  useEffect(() => {
    getPendingTransactionCount()
      .then((res) => {
        setPendingTransactions(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="row align-items-center text-center">
      <div className="col-4 p-r-0">
        <i className="far fa-envelope-open text-c-blue f-24" />
      </div>
      <div className="col-8 p-l-0">
        <h5>{pendingTransactions}</h5>
        <p className="text-muted m-b-0">Pending Trans</p>
      </div>
    </div>
  );
}

export default Pendingtransactionscount;
