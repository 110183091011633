import React from "react";
import { useEffect, useState } from "react";
import { getAccessPointCount } from "./blocksapicalls/apicalls";
function Accesspointscount() {
  const [accesspoints, setAccesspoints] = useState(0);
  useEffect(() => {
    getAccessPointCount()
      .then((res) => {
        setAccesspoints(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="col-sm-6 b-r-default p-b-20 p-t-20">
      <div className="row align-items-center text-center">
        <div className="col-4 p-r-0">
          <i className="fas fa-share-alt text-c-purple f-24" />
        </div>
        <div className="col-8 p-l-0">
          <h5>{accesspoints}</h5>
          <p className="text-muted m-b-0">Access Points</p>
        </div>
      </div>
    </div>
  );
}

export default Accesspointscount;
