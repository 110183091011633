export default function getSeverity(status) {
    switch (status) {
        case 'Active':
            return 'success';
        case 'Suspended':
            return 'warning';
        default:
            return ''

    }
};
export const accountStatus = [
    {
        value: "Activate Account",

    },
    {
        value: "Suspend Account"
    }
]

export const accountAccessPointStatus = [
    {
        value: "True",

    },
    {
        value: "False"
    }
]


export function getNin(status) {
    switch (status) {
        case 'True':
            return 'success';
        case 'False':
            return 'danger';
        default:
            return ''

    }
};

export function getAccess(status) {
    switch (status) {
        case 'True':
            return 'success';
        case 'False':
            return 'warning';
        default:
            return ''

    }
};

