import moment from 'moment';
export default function getSeverity(status) {
    switch (status) {
        case 'MTN':
            return 'warning';
        case 'GLO':
            return 'success';
        case 'AIRTEL':
            return 'danger';
        default:
            return 'info'

    }
};

export function getStatusSeverity(key) {
    switch (key) {
        case 'successful':
            return 'success'
        case 'pending':
            return 'warning'
        default:
            return 'info'

    }
}
export const providerFilterDropDown = [
    "MTN",
    "GLO",
    "AIRTEL",
    "9MOBILE",
    "AEDC",
    "IKEDC",
]
export const formatDate = (value) => {

    return value.toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
};
export const accountAccessPointStatus = [
    {
        value: "True",

    },
    {
        value: "False"
    }
]


export function getNin(status) {
    switch (status) {
        case 'True':
            return 'success';
        case 'False':
            return 'danger';
        default:
            return ''

    }
};

export function getAccess(status) {
    switch (status) {
        case 'True':
            return 'success';
        case 'False':
            return 'warning';
        default:
            return ''

    }
};

