import React from "react";
import { useEffect, useState } from "react";
import { getWalletCount } from "./blocksapicalls/apicalls";

function Walletscount() {
  const [wallets, setWallets] = useState(0);
  useEffect(() => {
    getWalletCount()
      .then((res) => {
        setWallets(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="col-sm-6 p-b-20 p-t-20">
      <div className="row align-items-center text-center">
        <div className="col-4 p-r-0">
          <i className="fas fa-sitemap text-c-green f-24" />
        </div>
        <div className="col-8 p-l-0">
          <h5>{wallets}</h5>
          <p className="text-muted m-b-0">Wallets</p>
        </div>
      </div>
    </div>
  );
}

export default Walletscount;
