import React from "react";
import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import Pageheader from "../Pageheader";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import getSeverity, {
  providerFilterDropDown,
  formatDate,
} from "./transactionfunctions/transactionhelper";
import { getStatusSeverity } from "./transactionfunctions/transactionhelper";
import { getUsersTransferCreditTransactionData } from "../transactionmanagement/transactionfunctions/apiCalls";
function Transfercredittransactions() {
  const toast = useRef(null);
  const [usersTransactions, setUsersTransactions] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    payable_id: { value: null, matchMode: FilterMatchMode.CONTAINS },
    receiver: { value: null, matchMode: FilterMatchMode.CONTAINS },
    wallet: { value: null, matchMode: FilterMatchMode.CONTAINS },
    created_at: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    account_details: { value: null, matchMode: FilterMatchMode.EQUALS },
    tranId: { value: null, matchMode: FilterMatchMode.EQUALS },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    amount: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const location = useLocation();
  // Access the current URL path
  const currentPath = window.location.pathname;
  // Split the path by '/' to get individual segments
  const pathSegments = currentPath.split("/");

  useEffect(() => {
    getUsersTransferCreditTransactionData()
      .then((result) => {
        setUsersTransactions(result);
        setLoadingTable(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingTable(false);
      });
  }, []);
  const cols = [
    { field: "tid", header: "TranID" },
    { field: "sender", header: "Status" },
    { field: "transaction_fee", header: "Transaction Fee" },
    { field: "receiver", header: "Type" },
    { field: "wallet", header: "Category" },
    { field: "tranId", header: "Description" },
    { field: "amount", header: "Amount" },
    { field: "created_at", header: "Date" },
  ];
  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, usersTransactions);
        doc.save("fundings.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(usersTransactions);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "users");
    });
  };
  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.status}
        severity={getStatusSeverity(rowData.status)}
      />
    );
  };
  const dateBodyTemplate = (rowData) => {
    let dates = new Date(rowData.created_at);
    return formatDate(dates);
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="dd/mm/yy"
        placeholder="dd/mm/yyyy"
        mask="99/99/9999"
      />
    );
  };
  const renderHeader = () => {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>

          <div className="flex">
            <Button
              type="button"
              icon="pi pi-file-excel"
              severity="success"
              rounded
              onClick={exportExcel}
              data-pr-tooltip="XLS"
            />
            <Button
              type="button"
              icon="pi pi-file-pdf"
              severity="warning"
              rounded
              onClick={exportPdf}
              data-pr-tooltip="PDF"
            />
          </div>
        </div>
      </>
    );
  };
  //   const providerItemTemplate = (option) => {
  //     return <Tag value={option} severity={getSeverity(option)} />;
  //   };
  //   const providerRowFilterTemplate = (options) => {
  //     return (
  //       <Dropdown
  //         value={options.value}
  //         options={providerFilterDropDown}
  //         onChange={(e) => options.filterApplyCallback(e.value)}
  //         itemTemplate={providerItemTemplate}
  //         placeholder="Select Status"
  //         className="p-column-filter"
  //         showClear
  //         style={{ minWidth: "4rem", maxWidth: "12rem" }}
  //       />
  //     );
  //   };
  const header = renderHeader();
  return (
    <div className="pcoded-content">
      <Toast ref={toast} />
      {/* Page-header start */}
      <Pageheader title={pathSegments[2]} subtitle={pathSegments[3]} />
      {/* Page-header end */}
      <div className="pcoded-inner-content">
        {/* Main-body start */}
        <div className="main-body">
          <div className="page-wrapper">
            {/* Page-body start */}
            <div className="page-body">
              <div className="card">
                <DataTable
                  value={usersTransactions}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  paginator
                  rows={10}
                  dataKey="id"
                  filters={filters}
                  filterDisplay="row"
                  loading={loadingTable}
                  globalFilterFields={[
                    "type",
                    "category",
                    "description",
                    "created_at",
                    "account_details",
                    "tranId",
                    "bank",
                    "sender",
                    "status",
                  ]}
                  header={header}
                  emptyMessage="No customers found."
                >
                  <Column field="tid" header="Trans ID" />
                  <Column
                    field="description"
                    style={{ minWidth: "12rem" }}
                    header="Description"
                  />
                  <Column
                    field="amount"
                    showFilterMenu={false}
                    filterMenuStyle={{ width: "14rem" }}
                    style={{ minWidth: "7rem" }}
                    // body={providerBodyTemplate}
                    // filter
                    // filterElement={providerRowFilterTemplate}
                    header="Amount"
                  />
                  <Column field="receiver" header="Beneficiary" />
                  <Column field="sender" header="Sender" />
                  <Column field="bank" header="Bank" />
                  <Column field="account_details" header="Account Details" />
                  <Column field="comment" header="Comments" />

                  <Column
                    field="status"
                    showFilterMenu={false}
                    filterMenuStyle={{ width: "14rem" }}
                    style={{ minWidth: "8rem" }}
                    body={statusBodyTemplate}
                    header="Status"
                  />
                  <Column
                    field="created_at"
                    header="Date"
                    sortable
                    filterField="date"
                    dataType="date"
                    style={{ minWidth: "14rem" }}
                    body={dateBodyTemplate}
                    filter
                    filterElement={dateFilterTemplate}
                  />
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Transfercredittransactions;
