import React, { useEffect, useState } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Electricitycharts from "./Electricitycharts";
import {
  getFundingTransactionChartData,
  getTransactionChartData,
} from "./chartsapicalls/apicalls";

function Transactioncharts() {
  const [fundingTransactionChart, setFundingTransactionChart] = useState([]);
  const [transactionChart, setTransactionChart] = useState([]);
  useEffect(() => {
    getFundingTransactionChartData()
      .then((res) => {
        setFundingTransactionChart(res);
      })
      .catch((err) => {
        console.log(err);
      });
    getTransactionChartData()
      .then((res) => {
        setTransactionChart(res);
      })
      .catch((err) => {
        console.log(err);
      });
  });
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Chart.js Bar Chart",
      },
    },
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Funding",
        data: fundingTransactionChart, //labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Transactions",
        data: transactionChart, //labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  return (
    <>
      <div className="col-md-12 col-lg-12">
        <div className="card">
          <div className="card-header">
            <h5>Transfers Transaction Chart</h5>
            <span>Data visualization for transfers</span>
          </div>
          <div className="card-block">
            <div id="morris-site-visit" />
          </div>
        </div>
      </div>
      <div>
        {/* Bar Chart start */}
        <div className="col-md-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h5>Bar Chart</h5>
              <span>Data visualization for Funding versus transactions</span>
            </div>
            <div className="card-block">
              <Bar options={options} data={data} />
            </div>
          </div>
        </div>
        <Electricitycharts title="Electricity" />
      </div>
    </>
  );
}

export default Transactioncharts;
