import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import avatar from "../images/avatar-4.jpg";
import checkRole from "../config/userroles";
const role = checkRole("role");
function Sidebar() {
  useEffect(() => {
    return () => {};
  });
  const currentPath = window.location.pathname;
  // Split the path by '/' to get individual segments
  const pathSegments = currentPath.split("/");

  return (
    <>
      <nav className="pcoded-navbar">
        <div className="sidebar_toggle">
          <a>
            <i className="icon-close icons" />
          </a>
        </div>
        <div className="pcoded-inner-navbar main-menu">
          <div>
            <div className="main-menu-header">
              <img
                className="img-80 img-radius"
                src={avatar}
                alt="User-Profile-Image"
              />
              <div className="user-details">
                <span id="more-details">
                  {role}
                  {/* {localStorage.getItem("role").toUpperCase()} */}
                  {/* <i className="fa fa-caret-down" /> */}
                </span>
              </div>
            </div>
            {/* <div className="main-menu-content">
              <ul>
                <li className="more-details">
                  <a>
                    <i className="ti-user" />
                    View Profile
                  </a>
                  <a>
                    <i className="ti-settings" />
                    Settings
                  </a>
                  <a>
                    <i className="ti-layout-sidebar-left" />
                    Logout
                  </a>
                </li>
              </ul>
            </div> */}
          </div>

          <div className="p-15 p-b-0">
            <form className="form-material">
              <div className="form-group form-primary">
                <input
                  type="text"
                  name="footer-email"
                  className="form-control"
                />
                <span className="form-bar" />
                <label className="float-label">
                  <i className="fa fa-search m-r-10" />
                  Search Friend
                </label>
              </div>
            </form>
          </div>
          <div className="pcoded-navigation-label">Navigation</div>
          <ul className="pcoded-item pcoded-left-item">
            <li className={!pathSegments[2] ? "active" : ""}>
              <NavLink
                className="waves-effect waves-dark"
                // activeClassName="is-active"
                to="/dashboard"
              >
                <span className="pcoded-micon">
                  <i className="ti-home" />
                  <b>D</b>
                </span>
                <span className="pcoded-mtext">Dashboard</span>
                <span className="pcoded-mcaret" />
              </NavLink>
            </li>
          </ul>

          <div className="pcoded-navigation-label">USERS</div>
          <ul className="pcoded-item pcoded-left-item">
            <li
              className={
                pathSegments[2] === "users"
                  ? "pcoded-hasmenu active"
                  : "pcoded-hasmenu"
              }
            >
              <a className="waves-effect waves-dark">
                <span className="pcoded-micon">
                  <i className="ti-id-badge" />
                  <b>BC</b>
                </span>
                <span className="pcoded-mtext">Users Management</span>
                <span className="pcoded-mcaret" />
              </a>
              <ul className="pcoded-submenu">
                <li className="">
                  <NavLink
                    className="waves-effect waves-dark"
                    // activeClassName="is-active"
                    to="/dashboard/users"
                  >
                    <span className="pcoded-micon">
                      <i className="ti-angle-right" />
                    </span>
                    <span className="pcoded-mtext">Users list</span>
                    <span className="pcoded-mcaret" />
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>

          <div className="pcoded-navigation-label">FINANCES / ACCOUNTING</div>
          <ul className="pcoded-item pcoded-left-item">
            <li
              className={
                pathSegments[2] === "transactions"
                  ? "pcoded-hasmenu active"
                  : "pcoded-hasmenu "
              }
            >
              <a className="waves-effect waves-dark">
                <span className="pcoded-micon">
                  <i className="ti-id-badge" />
                  <b>BC</b>
                </span>
                <span className="pcoded-mtext">Transactions</span>
                <span className="pcoded-mcaret" />
              </a>
              <ul className="pcoded-submenu">
                <li className=" ">
                  <NavLink
                    className="waves-effect waves-dark"
                    // activeClassName="is-active"
                    to="/dashboard/transactions/billstransactions"
                  >
                    <span className="pcoded-micon">
                      <i className="ti-angle-right" />
                    </span>
                    <span className="pcoded-mtext">Bills </span>
                    <span className="pcoded-mcaret" />
                  </NavLink>
                </li>
                <li className=" ">
                  <NavLink
                    className="waves-effect waves-dark"
                    // activeClassName="is-active"
                    to="/dashboard/transactions/debittransactions"
                  >
                    <span className="pcoded-micon">
                      <i className="ti-angle-right" />
                    </span>
                    <span className="pcoded-mtext">Debits </span>
                    <span className="pcoded-mcaret" />
                  </NavLink>
                </li>
                <li className=" ">
                  <NavLink
                    className="waves-effect waves-dark"
                    // activeClassName="is-active"
                    to="/dashboard/transactions/credittransactions"
                  >
                    <span className="pcoded-micon">
                      <i className="ti-angle-right" />
                    </span>
                    <span className="pcoded-mtext">Credits </span>
                    <span className="pcoded-mcaret" />
                  </NavLink>
                </li>
                <li className=" ">
                  <NavLink
                    className="waves-effect waves-dark"
                    // activeClassName="is-active"
                    to="/dashboard/transactions/accountfunding"
                  >
                    <span className="pcoded-micon">
                      <i className="ti-angle-right" />
                    </span>
                    <span className="pcoded-mtext">Account Funding </span>
                    <span className="pcoded-mcaret" />
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>

          {role === "superadmin" && (
            <>
              <div className="pcoded-navigation-label">ACCOUNT MANAGEMENT</div>
              <ul className="pcoded-item pcoded-left-item">
                <li
                  className={
                    pathSegments[2] === "account"
                      ? "pcoded-hasmenu active"
                      : "pcoded-hasmenu "
                  }
                >
                  <a className="waves-effect waves-dark">
                    <span className="pcoded-micon">
                      <i className="ti-id-badge" />
                      <b>A</b>
                    </span>
                    <span className="pcoded-mtext">ACCOUNTS</span>
                    <span className="pcoded-mcaret" />
                  </a>
                  <ul className="pcoded-submenu">
                    <li>
                      <NavLink
                        to="/dashboard/account/createadmin"
                        className="waves-effect waves-dark"
                      >
                        <span className="pcoded-micon">
                          <i className="ti-angle-right" />
                        </span>
                        <span className="pcoded-mtext">Create Admin</span>
                        <span className="pcoded-mcaret" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/dashboard/account/manageadmin"
                        className="waves-effect waves-dark"
                      >
                        <span className="pcoded-micon">
                          <i className="ti-angle-right" />
                        </span>
                        <span className="pcoded-mtext">Manage Admin</span>
                        <span className="pcoded-mcaret" />
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </>
          )}
        </div>
      </nav>
    </>
  );
}

export default Sidebar;
