
export default function kfigureformatter(num) {
    return (

        Math.abs(num) > 999 && Math.abs(num) < 1000000 ?
            Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K' :
            Math.abs(num) > 999999 ? Math.sign(num) * ((Math.abs(num) / 1000000).toFixed()) + 'M'
                : Math.sign(num) * Math.abs(num)

    );
}
