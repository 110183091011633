import React, { useEffect, useState, useRef } from "react";
import Pageheader from "../Pageheader";
import { useFormik } from "formik";
import Yup from "yup";
import { getAdminRoles, registerAccount } from "./apicalls/apicalls";
import { Toast } from "primereact/toast";
import checkRole from "../../config/userroles";
function Createadmin() {
  const [roles, setRoles] = useState([]);
  const [rolesId, setRolesId] = useState([]);
  const toast = useRef(null);
  const status = [
    {
      value: "active",
    },
    {
      value: "Suspended",
    },
  ];
  useEffect(() => {
    const role = checkRole("role");
    if (role !== "superadmin") {
      return (window.location.href = "/dashboard");
    } else {
      getAdminRoles()
        .then((res) => {
          console.log(res);
          setRoles(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  const currentPath = window.location.pathname;
  // Split the path by '/' to get individual segments
  const pathSegments = currentPath.split("/");
  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Required email";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "invalid email";
    }

    if (!values.password) {
      errors.password = "Required password";
    } else if (values.password.length < 5) {
      errors.password = "Password must be at least 5 characters";
    }

    if (!values.role) {
      errors.role = "Please select a role";
    }
    if (!values.status) {
      errors.status = "Please select a status";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      role: "",
      status: "",
    },
    validate,
    onSubmit: (values) => {
      let objectData = {
        email: values.email,
        role: values.role === 1 ? "modedrator" : "superadmin",
        password: values.password,
        status: values.status,
        roleid: values.role,
      };

      registerAccount(objectData)
        .then((result) => {
          result === "Account created successfully"
            ? toast.current.show({
                severity: "success",
                summary: "Sucess",
                detail: result,
              })
            : toast.current.show({
                severity: "error",
                summary: "Error",
                detail: result,
              });
          formik.resetForm();
        })
        .catch((err) => {
          alert(JSON.stringify(err));
        });
      //alert(JSON.stringify(values, null, 2));
    },
  });
  return (
    <div>
      <Toast ref={toast} />
      <div className="pcoded-content">
        {/* <Toast ref={toast} /> */}
        {/* Page-header start */}
        <Pageheader title={pathSegments[2]} subtitle={pathSegments[2]} />
        {/* Page-header end */}
        <div className="pcoded-inner-content">
          {/* Main-body start */}
          <div className="main-body">
            <div className="page-wrapper">
              {/* Page-body start */}
              <div className="page-body">
                <div className="card">
                  <div className="col-md-7">
                    <div className="card">
                      <div className="card-header">
                        <h5>Create Administrators</h5>
                        {/*<span>Add class of <code>.form-control</code> with <code>&lt;input&gt;</code> tag</span>*/}
                      </div>
                      <div className="card-block">
                        <form
                          onSubmit={formik.handleSubmit}
                          className="form-material"
                        >
                          <div className="form-group form-default form-static-label">
                            <input
                              type="email"
                              id="email"
                              name="email"
                              className="form-control"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.email}
                              placeholder="Enter Email"
                            />
                            {formik.touched.email && formik.errors.email ? (
                              <div>
                                <span style={{ color: "red" }}>
                                  {formik.errors.email}
                                </span>
                              </div>
                            ) : null}

                            <>
                              <span className="form-bar" />
                              <label className="float-label">
                                Email (exa@gmail.com)
                              </label>
                            </>
                          </div>
                          <div className="form-group form-default form-static-label">
                            <input
                              type="password"
                              id="password"
                              name="password"
                              className="form-control"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.password}
                              placeholder="Enter Password"
                            />
                            {formik.touched.password &&
                            formik.errors.password ? (
                              <div>
                                <span style={{ color: "red" }}>
                                  {formik.errors.password}
                                </span>
                              </div>
                            ) : null}
                            <span className="form-bar" />
                            <label className="float-label">Password</label>
                          </div>
                          <div className="form-group row">
                            {/* <label class="col-sm-2 col-form-label">Role</label> */}
                            <div className="col-sm-12 col-lg-12 col-md-12">
                              <select
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="role"
                                id="role"
                                className="form-control"
                              >
                                <option>Select Role</option>
                                {roles.map(({ role, id }, index) => {
                                  return (
                                    <option key={index} value={id}>
                                      {role}
                                    </option>
                                  );
                                })}
                              </select>

                              {formik.touched.role && formik.errors.role ? (
                                <div>
                                  <span style={{ color: "red" }}>
                                    {formik.errors.role}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group">
                            <select
                              onChange={formik.handleChange}
                              name="status"
                              id="status"
                              className="form-control"
                            >
                              <option>Select Status</option>
                              {status.map((stat, index) => {
                                return (
                                  <option key={index}>{stat.value}</option>
                                );
                              })}
                            </select>
                            {formik.touched.status && formik.errors.status ? (
                              <div>
                                <span style={{ color: "red" }}>
                                  {formik.errors.status}
                                </span>
                              </div>
                            ) : null}
                          </div>

                          {/* <div className="form-group form-default form-static-label">
                            <input
                              type="text"
                              name="footer-email"
                              className="form-control"
                              maxLength={6}
                              placeholder="Enter only 6 char"
                            />
                            <span className="form-bar" />
                            <label className="float-label">
                              Max length 6 char
                            </label>
                          </div> */}
                          <div className="form-group">
                            <button
                              type="submit"
                              className="btn btn-primary btn-md btn-block waves-effect waves-light text-center m-b-20"
                            >
                              Create account
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Createadmin;
