import React, { useRef, useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import Pageheader from "../Pageheader";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode } from "primereact/api";
import { getAdmins } from "./apicalls/apicalls";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";
import { updateAccountStatus } from "./apicalls/apicalls";
import getSeverity, {
  accountStatus,
  formatDate,
} from "./helperfunction/adminhelper";
import checkRole from "../../config/userroles";
function Manageadmin() {
  const currentPath = window.location.pathname;
  // Split the path by '/' to get individual segments
  const pathSegments = currentPath.split("/");
  const [loadingTable, setLoadingTable] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [adminData, setAdminData] = useState([]);
  const [admin, setAdmin] = useState({});
  const [selectedStatus, setSelectedStatus] = useState(null);

  const [adminDialog, setAdminDialog] = useState(false);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    id: { value: null, matchMode: FilterMatchMode.CONTAINS },
    email: { value: null, matchMode: FilterMatchMode.CONTAINS },
    role: { value: null, matchMode: FilterMatchMode.EQUALS },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    created_at: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  useEffect(() => {
    const role = checkRole("role");
    if (role !== "superadmin") {
      return (window.location.href = "/dashboard");
    } else {
      getAdmins()
        .then((response) => {
          setAdminData(response);
          setLoadingTable(false);
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });
  const hideDialog = () => {
    //setSubmitted(false);
    setAdminDialog(false);
  };
  const findIndexById = (id) => {
    let index = -1;

    for (let i = 0; i < adminData.length; i++) {
      if (adminData[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  };
  const editAdmins = (admin) => {
    setAdmin({ ...admin });
    setAdminDialog(true);
  };
  const dateBodyTemplate = (rowData) => {
    let dates = new Date(rowData.created_at);
    return formatDate(dates);
  };
  const statusBodyTemplate = (rowData) => {
    return (
      <Tag value={rowData.status} severity={getSeverity(rowData.status)} />
    );
  };

  const toast = useRef(null);
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
      </>
    );
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          outlined
          className="mr-2"
          onClick={() => editAdmins(rowData)}
        />
      </React.Fragment>
    );
  };
  const adminDialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button
        label="Save"
        icon="pi pi-check"
        onClick={() => [
          setLoadingTable(true),
          updateAccountStatus({
            id: admin.id,
            status:
              selectedStatus === "Suspend Account" ? "suspended" : "active",
          })
            .then((res) => {
              if (res === "Account Updated") {
                setLoadingTable(false);
                setAdminDialog(false);
                toast.current.show({
                  severity: "success",
                  summary: "Successful",
                  detail: res,
                });
                let _admins = [...adminData];
                let _admin = { ...admin };

                if (_admin.id) {
                  const index = findIndexById(admin.id);
                  _admin = {
                    ..._admin,
                    status: selectedStatus === "Activate Account" ? "1" : "0",
                  };
                  _admins[index] = _admin;
                }
                setAdminData(_admins);
                setAdmin(_admin);
              }
            })
            .catch((error) => {
              setLoadingTable(false);
              alert("error" + error);
            }),
        ]}
      />
    </React.Fragment>
  );
  const header = renderHeader();
  return (
    <div>
      <div className="pcoded-content">
        <Toast ref={toast} />
        {/* Page-header start */}
        <Pageheader title={pathSegments[2]} subtitle={pathSegments[2]} />
        {/* Page-header end */}
        <div className="pcoded-inner-content">
          {/* Main-body start */}
          <div className="main-body">
            <div className="page-wrapper">
              {/* Page-body start */}
              <div className="page-body">
                <div className="card">
                  <DataTable
                    value={adminData}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    paginator
                    rows={10}
                    dataKey="id"
                    filters={filters}
                    filterDisplay="row"
                    loading={loadingTable}
                    globalFilterFields={[
                      "id",
                      "email",
                      "role",
                      "status",
                      "created_at",
                    ]}
                    header={header}
                    emptyMessage="No admins found."
                  >
                    <Column
                      header="Id"
                      field="id"
                      style={{ maxWidth: "12rem", minWidth: "12rem" }}
                    />
                    <Column
                      header="Email"
                      field="email"
                      style={{ maxWidth: "12rem", minWidth: "12rem" }}
                      filterPlaceholder="Search email"
                    />
                    <Column
                      header="Admin Role"
                      field="role"
                      style={{ maxWidth: "12rem", minWidth: "12rem" }}
                      filterPlaceholder="Search Role"
                    />
                    <Column
                      header="Account Status"
                      field="status"
                      body={statusBodyTemplate}
                      style={{ maxWidth: "12rem", minWidth: "12rem" }}
                      filterPlaceholder="Search Status"
                    />
                    <Column
                      header="Date Created"
                      style={{ maxWidth: "12rem", minWidth: "12rem" }}
                      field="created_at"
                      sortable
                      filterField="date"
                      dataType="date"
                      body={dateBodyTemplate}
                    />
                    <Column
                      header="Actions"
                      body={actionBodyTemplate}
                      exportable={false}
                      style={{ minWidth: "12rem" }}
                    ></Column>
                  </DataTable>

                  <Dialog
                    visible={adminDialog}
                    style={{ width: "32rem" }}
                    breakpoints={{ "960px": "75vw", "641px": "90vw" }}
                    header="Account Update"
                    modal
                    className="p-fluid"
                    footer={adminDialogFooter}
                    onHide={hideDialog}
                  >
                    <div className="field">
                      <label
                        htmlFor="name"
                        style={{ fontWeight: "bold" }}
                        className="font-bold"
                      >
                        Update Account For {admin.email + " "}
                      </label>
                      <div className="card flex justify-content-center">
                        <Dropdown
                          value={selectedStatus}
                          onChange={(e) => setSelectedStatus(e.value)}
                          options={accountStatus}
                          optionLabel="value"
                          placeholder="Select Status"
                          className="w-full md:w-14rem"
                        />
                      </div>
                    </div>
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Manageadmin;
