export default function getSeverity(status) {
    switch (status) {
        case 'Active'.toLowerCase():
            return 'success';
        case 'Suspended'.toLowerCase():
            return 'warning';
        default:
            return ''

    }
};
export const formatDate = (value) => {

    return value.toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
};
export const accountStatus = [
    {
        value: "Activate Account",

    },
    {
        value: "Suspend Account"
    }
]

export const accountAccessPointStatus = [
    {
        value: "True",

    },
    {
        value: "False"
    }
]


export function getNin(status) {
    switch (status) {
        case 'True':
            return 'success';
        case 'False':
            return 'danger';
        default:
            return ''

    }
};

export function getAccess(status) {
    switch (status) {
        case 'True':
            return 'success';
        case 'False':
            return 'warning';
        default:
            return ''

    }
};

