import React, { useState, useRef } from "react";
import logo from "../../images/pay_support.png";
import Cookies from "universal-cookie";
import { Toast } from "primereact/toast";
import { SignIn } from "./authfunctions/authApiCalls";
import { useNavigate, Navigate } from "react-router-dom";
function Auth() {
  const navigate = useNavigate();
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorLogin, setErrorLogin] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const toast = useRef(null);

  const accept = () => {
    localStorage.clear();
    toast.current.show({
      severity: "success",
      summary: "Confirmed",
      detail: "Your login successful",
      life: 3000,
    });
  };
  const doSignin = () => {
    const cookies = new Cookies();

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      setErrorEmail("Are you missing out on Email? ");
      return setTimeout(() => {
        setErrorEmail("");
      }, 3000);
    } else if (password.length < 3) {
      setErrorPassword("Are you missing out on password? ");
      return setTimeout(() => {
        setErrorPassword("");
      }, 3000);
    } else {
      SignIn(email, password)
        .then((res) => {
          if (res.data.message === "Invalid password") {
            setErrorLogin(res.data.message);
            setTimeout(() => {
              setErrorLogin("");
            }, 4000);
          } else {
            accept();
            //alert(res.data.message.role);
            var token = res.data.message.token;
            cookies.set("access_token", token, {
              path: "/",
              httpOnly: false,
              secure: true, // Set to true if using HTTPS
            });

            cookies.set("role", res.data.message.role, {
              path: "/",
              httpOnly: false,
              secure: true, // Set to true if using HTTPS
            });

            //localStorage.setItem("token", res.data.message.token);
            //localStorage.setItem("role", res.data.message.role);
            window.location.href = "/dashboard";
          }
        })
        .catch((err) => {
          setErrorLogin("Invalid Email");
          setTimeout(() => {
            setErrorLogin("");
          }, 4000);
        });
    }
  };
  return (
    <div>
      <Toast ref={toast} />
      {/* Pre-loader end */}
      <section className="login-block">
        {/* Container-fluid starts */}
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              {/* Authentication card start */}
              <form className="md-float-material form-material">
                <div className="text-center">
                  <img
                    src={logo}
                    style={{ width: "20%" }}
                    alt="paysupportlogo.png"
                  />
                </div>
                <div className="auth-box card">
                  <div className="card-block">
                    <div className="row m-b-20">
                      <div className="col-md-12">
                        <h3 className="text-center">Sign In</h3>
                      </div>
                    </div>
                    <div className="form-group form-primary">
                      <input
                        type="text"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control"
                      />
                      <span className="form-bar" />
                      <label className="float-label">Your Email Address</label>
                    </div>
                    <span
                      style={{
                        color: "red",
                        // padding: "100px",
                        float: "right",
                      }}
                    >
                      {errorEmail}
                    </span>
                    <div className="form-group form-primary">
                      <input
                        type="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-control"
                      />
                      <span className="form-bar" />
                      <label className="float-label">Password</label>
                    </div>
                    <span style={{ color: "red" }}>{errorPassword}</span>
                    <div className="row m-t-25 text-left">
                      <div className="col-12">
                        <div className="checkbox-fade fade-in-primary d-">
                          <label>
                            <input type="checkbox" defaultValue />
                            <span className="cr">
                              <i className="cr-icon icofont icofont-ui-check txt-primary" />
                            </span>
                            <span className="text-inverse">Remember me</span>
                          </label>
                        </div>
                        <div className="forgot-phone text-right f-right">
                          <a
                            href="auth-reset-password.html"
                            className="text-right f-w-600"
                          >
                            {" "}
                            Forgot Password?
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="row m-t-30">
                      <div className="col-md-12">
                        <button
                          type="button"
                          onClick={doSignin}
                          className="btn btn-primary btn-md btn-block waves-effect waves-light text-center m-b-20"
                        >
                          Sign in
                        </button>
                        <span style={{ color: "red" }}>{errorLogin}</span>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-10">
                        <p className="text-inverse text-left m-b-0">
                          Thank you.
                        </p>
                        <p className="text-inverse text-left">
                          <a href="https://www.paysupport.ng" target="_blank">
                            <b>Back to website</b>
                          </a>
                        </p>
                      </div>
                      <div className="col-md-2">
                        <img
                          src="assets/images/auth/Logo-small-bottom.png"
                          alt="small-logo.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              {/* end of form */}
            </div>
            {/* end of col-sm-12 */}
          </div>
          {/* end of row */}
        </div>
        {/* end of container-fluid */}
      </section>
    </div>
  );
}

export default Auth;
