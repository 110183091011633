import React, { useState } from "react";
import { useEffect } from "react";
import "chart.js/auto";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { getCableChartData } from "./chartsapicalls/apicalls";
import { Line } from "react-chartjs-2";

function Cablecharts(props) {
  const [cableChart, setCableChart] = useState([]);
  useEffect(() => {
    getCableChartData()
      .then((res) => {
        setCableChart(res);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
      );
    };
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Report for the month",
      },
    },
  };

  const labels = ["DSTV", "GOTV", "STARTIMES"];

  const data = {
    labels,
    datasets: [
      {
        label: "Cable Consumption",
        data: cableChart,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      // {
      //   label: 'Dataset 2',
      //   data: [2050,1200,1920],
      //   borderColor: 'rgb(53, 162, 235)',
      //   backgroundColor: 'rgba(53, 162, 235, 0.5)',
      // },
    ],
  };
  return (
    <div className="col-md-12 col-lg-12">
      <div className="card">
        <div className="card-header">
          <h5>{props.vendors} Chart</h5>
          <span>
            Data visualization for cable subscriptions across providers.
          </span>
        </div>
        <div className="card-block">
          <Line data={data} options={options} />
        </div>
      </div>
    </div>
  );
}

export default Cablecharts;
