import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import Sidebar from './components/Sidebar';
import Users from './components/usermanagement/Users';
import Accountfundingtransactions from './components/transactionmanagement/Accountfundingtransactions';
import Transferdebittransactions from './components/transactionmanagement/Transferdebittransactions';
import Transfercredittransactions from './components/transactionmanagement/Transfercredittransactions';
import Billstransactions from './components/transactionmanagement/Billstransactions';
import Manageadmin from './components/accountmanagement/Manageadmin';
import Createadmin from './components/accountmanagement/Createadmin';
import Auth from './components/auth/Auth';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import checkRole from './config/userroles';
function App() {
  useEffect(() => {



  }, []);
  const navigate = useNavigate()
  // Access the current URL path
  const currentPath = window.location.pathname;
  // Split the path by '/' to get individual segments
  const pathSegments = currentPath.split("/");

  return (
    <>
      {
        pathSegments[1] === "login" ? <Routes ><Route path='/login' element={<Auth />} /></Routes> :


          <div id="pcoded" className="pcoded">
            <div className="pcoded-overlay-box" />
            <div className="pcoded-container navbar-wrapper">
              <Header />
              <div className="pcoded-main-container">
                <div className="pcoded-wrapper">

                  <Sidebar />
                  <Routes>
                    {/* <Sidebar /> */}
                    <Route path="/" element={<Home />} />
                    <Route path="/dashboard" element={<Home />} navigate={navigate} />
                    {/* USERS ROUTES */}
                    <Route path='/dashboard/users' element={<Users navigate={navigate} />} />
                    <Route path='/dashboard/transactions/billstransactions' element={<Billstransactions navigate={navigate} />} />
                    <Route path='/dashboard/transactions/accountfunding' element={<Accountfundingtransactions navigate={navigate} />} />
                    <Route path='/dashboard/transactions/debittransactions' element={<Transferdebittransactions navigate={navigate} />} />
                    <Route path='/dashboard/transactions/credittransactions' element={<Transfercredittransactions navigate={navigate} />} />
                    <Route path='/dashboard/account/manageadmin' element={<Manageadmin navigate={navigate} />} />
                    <Route path='/dashboard/account/createadmin' element={<Createadmin navigate={navigate} />} />
                  </Routes>

                </div>
              </div>
            </div>
          </div>
      }
    </>

  );
}

export default App;
