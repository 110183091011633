import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import kfigureformatter from "../Helpers/kfigureformatter";
import { getFundingSum } from "./blocksapicalls/apicalls";
function Fundingsum() {
  const [fundings, setFundings] = useState(0);
  useEffect(() => {
    getFundingSum()
      .then((res) => {
        setFundings(kfigureformatter(res));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="card mat-clr-stat-card text-white green ">
      <div className="card-block">
        <div className="row">
          <div className="col-3 text-center bg-c-green">
            <i className="fas fa-check mat-icon f-24" />
          </div>
          <div className="col-9 cst-cont">
            <h5>{fundings}</h5>
            <p className="m-b-0">Funding / Inflows</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fundingsum;
