import React from "react";
import { useEffect, useState } from "react";
import { getUsersCount } from "./blocksapicalls/apicalls";

function Userscount() {
  const [users, setUsers] = useState(0);
  useEffect(() => {
    getUsersCount()
      .then((res) => {
        setUsers(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="col-sm-6 b-r-default p-b-20 p-t-20">
      <div className="row align-items-center text-center">
        <div className="col-4 p-r-0">
          <i className="far fa-user text-c-purple f-24" />
        </div>
        <div className="col-8 p-l-0">
          <h5>{users}</h5>
          <p className="text-muted m-b-0">Users</p>
        </div>
      </div>
    </div>
  );
}

export default Userscount;
