import React from "react";
import { useEffect, useState } from "react";
import { getTransactionSum } from "./blocksapicalls/apicalls";
import kfigureformatter from "../Helpers/kfigureformatter";
function Transactionsum() {
  const [transactions, setTransactions] = useState(0);
  useEffect(() => {
    getTransactionSum()
      .then((res) => {
        setTransactions(kfigureformatter(res));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="card mat-clr-stat-card text-white blue">
      <div className="card-block">
        <div className="row">
          <div className="col-3 text-center bg-c-blue">
            <i className="fas fa-history mat-icon f-24" />
          </div>
          <div className="col-9 cst-cont">
            <h5>{transactions}</h5>
            <p className="m-b-0">Transactionals Total</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Transactionsum;
