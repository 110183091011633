import axios from "axios";
import { base_url } from "../../../config/globals";
export function SignIn(email, password) {
    return new Promise((resolve, reject) => {
        let options = {
            method: 'POST',
            url: base_url + '/login',
            data: { email: email, password: password }
        }
        axios.request(options).then((result) => {

            return resolve(result)
        }).catch(err => {
            reject(JSON.stringify(err))
        })
    })
}