import React, { useState } from "react";
import { useEffect } from "react";
import "chart.js/auto";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { getAirtimeChartData } from "./chartsapicalls/apicalls";
function Airtimecharts(props) {
  const [airtimeChart, setAirtimeChart] = useState([]);
  useEffect(() => {
    getAirtimeChartData()
      .then((res) => {
        setAirtimeChart(res);
        //alert(JSON.stringify(res));
      })
      .catch((err) => {
        alert("err " + err);
      });
    ChartJS.register(ArcElement, Tooltip, Legend);
  }, []);
  const data = {
    labels: ["9-MOBILE", "MTN", "AIRTEL", "GLO"],
    datasets: [
      {
        label: "AIRTIME PURCHASE",
        data: airtimeChart,
        backgroundColor: [
          "rgb(244,198,53,0.2)",
          "rgb(237,46,45,0.2)",
          "rgb(66,177,39,0.2)",
          "rgb(181,195,27,0.2)",
        ],
        borderColor: [
          "rgb(244,198,53)",
          "rgb(237,46,45,1)",
          "rgb(66,177,39,1)",
          "rgb(181,195,27,1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <div className="col-md-12 col-lg-6">
      <div className="card">
        <div className="card-header">
          <h5>{props.vendors} Chart</h5>
          <span>Data visualization for airtime purchase across networks.</span>
        </div>
        <div className="card-block">
          <Doughnut data={data} />
        </div>
      </div>
    </div>
  );
}

export default Airtimecharts;
