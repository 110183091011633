import React from "react";
import { useEffect, useState } from "react";
import { getTransactionCount } from "./blocksapicalls/apicalls";
function Transactionscount() {
  const [transactions, setTransactions] = useState(0);
  useEffect(() => {
    getTransactionCount()
      .then((res) => {
        setTransactions(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="col-sm-6 p-b-20 p-t-20 b-r-default">
      <div className="row align-items-center text-center">
        <div className="col-4 p-r-0">
          <i className="far fa-file-alt text-c-red f-24" />
        </div>
        <div className="col-8 p-l-0">
          <h5>{transactions}</h5>
          <p className="text-muted m-b-0">Transactions</p>
        </div>
      </div>
    </div>
  );
}

export default Transactionscount;
