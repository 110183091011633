import React, { useState } from "react";
import { useEffect } from "react";
import "chart.js/auto";
import { getElectricityChartData } from "./chartsapicalls/apicalls";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

function Electricitycharts(props) {
  const [electricityChart, setElectricityChart] = useState([]);
  useEffect(() => {
    getElectricityChartData()
      .then((res) => {
        setElectricityChart(res);
      })
      .catch((err) => {
        console.log(err);
      });
    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend
    );
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Report for the month",
      },
    },
  };

  const labels = [
    "AEDC",
    "EKEDC",
    "EEDC",
    "IBEDC",
    "IKEDC",
    "JEDC",
    "KAEDCO",
    "KEDC",
    "PHEDC",
    "YEDC",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Electricity Consumption",
        data: electricityChart,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      // {
      //   label: 'Dataset 2',
      //   data: [2050,1200,1920],
      //   borderColor: 'rgb(53, 162, 235)',
      //   backgroundColor: 'rgba(53, 162, 235, 0.5)',
      // },
    ],
  };
  return (
    <div className="col-md-12 col-lg-12">
      <div className="card">
        <div className="card-header">
          <h5>{props.vendors} Chart</h5>
          <span>
            Data visualization for cable subscriptions across providers.
          </span>
        </div>
        <div className="card-block">
          <Line data={data} options={options} />
        </div>
      </div>
    </div>
  );
}

export default Electricitycharts;
