import React from "react";
import { useEffect, useState } from "react";
import { getActiveUsersCount } from "./blocksapicalls/apicalls";

function Activeuserscount() {
  const [activeusers, setActiveUsers] = useState(0);
  useEffect(() => {
    getActiveUsersCount()
      .then((res) => {
        setActiveUsers(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="col-sm-6 b-r-default p-b-20 p-t-20">
      <div className="row align-items-center text-center">
        <div className="col-4 p-r-0">
          <i className="fas fa-volume-down text-c-green f-24" />
        </div>
        <div className="col-8 p-l-0">
          <h5>{activeusers}</h5>
          <p className="text-muted m-b-0">Active Users</p>
        </div>
      </div>
    </div>
  );
}

export default Activeuserscount;
